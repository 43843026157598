
import { withTranslation } from 'react-i18next';
//import React from 'react';
import Steps, { Step } from 'rc-steps';
import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import React, { Component, Fragment } from 'react';
import './Stddetail.css';
import JobInvites from './containers/JobInvites';
import JobsApplied from './containers/JobsApplied';
import JobsInterviews from './containers/JobsInterviews';
import JobsOffers from './containers/JobsOffers';
import stdprofile from 'assets/images/img_avatar.png';
import { Button, InlineNotification } from 'carbon-components-react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import Avatar from 'components/common/Avatar';
import { uploadResume } from 'actions/candidate/dashboard';
import {
  getCandidateProfile,
} from 'actions/talentSearch/candidatesList';


class Stddetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount(){
    this.handleGetCandidateProfile();
  }

  handleGetCandidateProfile = () => {
    const { profileId } = this.props || {};
    this.props.getCandidateProfile(profileId).then((res)=>{
      if(res && !res.error){
        const { documents } = res || {};
        const reqDoc = documents && documents.filter((document)=>{
          const { typeId } = document || {};
          return typeId == 1;
        })
        const stateData = {};
        if(reqDoc && Array.isArray(reqDoc) && reqDoc.length>=1){
          stateData.personResume = reqDoc[0];
        }
        this.setState({
          ...stateData,
          candidateProfile: res,
        });
      }
    });
  }

  handleUploadFile = (element) => {
    const { profileId } = this.props;
    const fileData = element.target.files;
    const currFile = new FormData();
    currFile.append('file', fileData[0]);
    const data = {
      profileId, 
      currFile
    }
    this.props.uploadResume(data).then((res)=>{
      if(res && !res.error){
        this.handleGetCandidateProfile();
        this.setState({
          showResumeUpdateNotif: true
        },()=>{
          setTimeout(()=>{
            this.setState({
              showResumeUpdateNotif: false
            })
          },6000)
        })
      }else{
        console.error('error',res.error)
      }
    }).catch((e)=>{
      console.error('error',e)
    })

  }

  render () {
    const {
      firstname, lastname, profileId,
      picture,
      t,
      isAppSubmitted,
      isRoomAssessmentDone,
      isProfilingAssessmentDone,
      isLiveInterviewDone,
      studentSlots,
      instSlots,
      status,
      push,
      publicUuid,
      email,
    } = this.props || {};

    const { personResume, showResumeUpdateNotif } = this.state || {};
    const { cvLink: personResumeCvLink } = personResume || {};
  
    let fixedInstSlots = [];
    let fixedStdSlots = [];
    let flexibleInstSlots = [];
    let flexibleStdSlots = [];
    if (instSlots && Array.isArray(instSlots) && instSlots.length > 0) {
      fixedInstSlots = instSlots.filter(res => res && res.slotType === 'fixed');
    }
    if (studentSlots && Array.isArray(studentSlots) && studentSlots.length > 0) {
      fixedStdSlots = studentSlots.filter(res => res && res.institutionslots && res.institutionslots.slotType === 'fixed');
    }
    if (instSlots && Array.isArray(instSlots) && instSlots.length > 0) {
      flexibleInstSlots = instSlots.filter(res => res && res.slotType === 'flexible');
    }
    if (studentSlots && Array.isArray(studentSlots) && studentSlots.length > 0) {
      flexibleStdSlots = studentSlots.filter(res => res && res.institutionslots && res.institutionslots.slotType === 'flexible');
    }
    let stepOneStatus = 'wait';
    let stepOneTitle = 'invitesPendingTab';
    let registerLink = `/app/campus/candidate/register/profile/${profileId}/stage/index/0`;
    const currentStep = 0;
    if (isAppSubmitted === true) {
      stepOneStatus = 'completed';
      stepOneTitle = 'completed';
      registerLink = '/app/profile';
    } else {
      stepOneTitle = 'invitesPendingTab';
      stepOneStatus = 'wait';
    }
    let steptwoStatus = 'wait';
    let stepTwoTitle = 'invitesPendingTab';
    if (fixedInstSlots.length === fixedStdSlots.length) {
      steptwoStatus = 'completed';
      stepTwoTitle = 'completed';
    }
    let stepThreeStatus = 'wait';
    let stepThreetitle = 'invitesPendingTab';
    if (flexibleInstSlots.length === flexibleStdSlots.length) {
      stepThreeStatus = 'completed';
      stepThreetitle = 'completed';
    }
    let stepFotStatus = 'wait';
    let stepfourTitle = 'invitesPendingTab';
    let videoUrl = '/app/campus/candidate/slots';
    if (status === 'Draft' || status === 'Awaiting response') {
      const currEnv = window.location.host;
      let recLink = '';
      if (currEnv === 'cstage.x0pa.ai') {
        recLink = 'https://demo.recright.com/interview/public/';
      } else if (currEnv === 'geip.x0pa.ai') {
        recLink = 'https://www.recright.com/interview/public/';
      }
      videoUrl = `${recLink}${publicUuid}?email=${email}`;
      stepFotStatus = 'pending';
      stepfourTitle = 'pending';
      //videoUrl = '';
    }
    if (status === 'Completed') {
      stepFotStatus = 'completed';
      stepfourTitle = 'completed';
    }
  
    const stepFourMainInfo = isRoomAssessmentDone ? 'Completed' : 'Pending';
    const stepTwoMainInfo = isProfilingAssessmentDone ? 'Completed' : 'Pending';
    const stepThreeMainInfo = isLiveInterviewDone ? 'Completed' : 'Pending';
  
    // Job details
    // const CardLayout = ({ details }) => {
    //   const {
    //     jobId,
    //     jobname: { nameName } = {},
    //     appDate,
    //     status,
    //     openDate,
    //   } = details;
  
    //   const newDate = new Date(openDate);
  
    return (
      <Fragment>
        <div className="h6 mb-2">
          {t('progressHeading')}
        </div>
        <div className="h6 text-dark mb-2">
          {t('progressSubHeading')}
        </div>
        <div className="mb-2">
          {t('progressMsg1')}
        </div>
        <div className="mb-2">
          {t('progressMsg2')}
        </div>
        {/* <div className="mb-2">
          {t('progressMsg3')}
        </div> */}
        <div className="mb-2">
          {t('progressMsg4')}
        </div>
        <div className="mb-4 font-weight-bold small text-uppercase">
          {t('progressMsg5')}
        </div>
        <Steps current={currentStep}>
          <Step title={<div className="xpa-link" onClick={() => { push && push(registerLink) }}>{t(stepOneTitle)}</div>} status={stepOneStatus} description={t('progressStep1')} />
          {
            isRoomAssessmentDone ? (
              <Step title={<div className="xpa-link">{t(stepFourMainInfo)}</div>} status={stepFourMainInfo} description={t('progressStep4')} />
            ) : (
              <Step title={<div className="xpa-link" onClick={() => { push && push({ pathname: videoUrl, state: { selectedTab: 0 } }) }}>{t(stepFourMainInfo)}</div>} status={stepFourMainInfo} description={t('progressStep4')} />
            )
          }
          {/* <Step title={<div onClick={() => { push && push({ pathname: videoUrl, state: { selectedTab: 1 } }) }} className="xpa-link">{t(stepTwoMainInfo)}</div>} status={stepTwoMainInfo} description={t('progressStep2')} /> */}
          <Step title={<div className="xpa-link" onClick={() => { push && push({ pathname: videoUrl, state: { selectedTab: 1 } }) }} >{t(stepThreeMainInfo)}</div>} status={stepThreeMainInfo} description={t('progressStep3')} />
        </Steps>
  
        <hr />
        <div className="font_hed_stdprof">
          {isAppSubmitted !== true && (
            <div>
              Register for GEIP - Global Entrepreneurial Internship Programme.
            </div>
          )}
          {isAppSubmitted === true && (
            <div>
              We’ve received your application for GEIP.
            </div>
          )}
        </div>
        <div className="mb-2 Profpic_area">
          {/* <img className="stp_img disp_img" src={stdprofile}  alt="Student Image"/> */}
          <Avatar
            user={{
              firstname,
              lastname,
              picture: picture ? picture : null,
            }}
            size="100"
            classNameInfo="rounded-circle"
          />
          <div className="Text_images disp_img left_sapace">
            {/* <div className="mt-3">
  
                                <label className="std_name">Student ID :</label>
                                <span> A1234567</span>
                              </div> */}
            <div className="mt-3">
              <label className="std_name">Student Name :{firstname} {lastname}</label>
  
              {/* <span> Runniga</span> */}
            </div>
            {/* <div className="mt-2">
                            <div className="font-weight-bold">
                              <a href={`/app/campus/job/${jobId}`}>{nameName}</a>
                            </div>
                            </div> */}
          </div>
        </div>
        <div className="pos_btn">
  
          <div className="bt_sub_pos ">
  
            {/* <Link className="bx--btn bx--btn--primary button-color bx--btn--sm mt-2 mr-3 w-100" to="candidate/register/profile/stage/index/0"
                      >Submit Application</Link> */}
  
  
  
            {isAppSubmitted !== true && (
              <Link className="bx--btn bx--btn--primary button-color bx--btn--sm mt-2 mr-3 w-100" to={`candidate/register/profile/${profileId}/stage/index/0`}
              >Submit Application</Link>
            )}
            {isAppSubmitted === true && (
              <Link className="bx--btn bx--btn--primary button-color bx--btn--sm mt-2 mr-3 w-100" to="/app/profile"
              >View Application</Link>
            )}
  
            {/* <Link className="bx--btn bx--btn--primary button-color bx--btn--sm mt-2 mr-3 w-100" to="/app/campus/candidate/register/profile"
                      >Submit Application</Link> */}
          </div>
  
        </div>
  
        <div className="d-flex">
          <div className="">
            <input
              type="file"
              className="bx--file-input"
              id={`upload-resume`}
              name={`upload-resume`}
              accept=".doc,.docx,.pdf"
              onChange={(e) => this.handleUploadFile(e)}
            />
            <div className="bx--file h-auto text-center newjob-upload-jd" data-file>
              <label
                id={`upload-resume`}
                htmlFor={`upload-resume`}
                className="bx--btn bx--btn--primary uploadFile upload-docs-min-width"
                role="button"
                tabIndex="0"
              >
                {personResume ? 'Update' : 'Upload'} Resume
              </label>
              <input
                type="file"
                className="bx--file-input"
                id={`upload-resume`}
                accept=".doc,.docx,.pdf"
                onChange={this.handleUploadFile}
                name={`upload-resume`}
                data-file-uploader
                data-target="[data-file-container]"
              />
              <div data-file-container className="bx--file-container m-0"></div>
            </div>
          </div>
          {personResume && 
            <a target="_blank" href={`/view/${personResumeCvLink}/cv?_=${Date.now()}`}>
              <Button className="ml-2" kind="tertiary">
                View Resume
              </Button>
            </a>
          }
        </div>
        {
          showResumeUpdateNotif && (
            <InlineNotification hideCloseButton lowContrast kind="success" title="Updated Resume Successfully" />
          )
        }
        <hr />
        <JobInvites />
  
        <JobsApplied />
  
        <JobsInterviews />
        <JobsOffers />
      </Fragment>
  
    );  
  }
};



const mapDispatchToProps = {
  uploadResume,
  getCandidateProfile
};

export default connect(null, mapDispatchToProps)(withTranslation()(Stddetail));
