import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import {
  Button,
  Accordion,
  AccordionItem,
  RadioButton,
  TextArea,
  TextInput,
  InlineNotification,
} from 'carbon-components-react';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import InterviewFeedbackTable from './InterviewFeedbackTable';
import './InterviewFeedbackForm.css';
import moment from 'moment';

const InterviewFeedbackForm = (props) => {
  const {
    t,
    handleShowFeedback,
    showFeedback,
    handleHideForm,
    handleChange,
    handleSubmitFeedbackForm,
    state,
    handleChangeRadioBtn,
    lastname,
    firstname,
    hideAddFeedBtn,
    responseData,
    userFeedbackDone,
    userId,
    propUserId,
    handleEditFeedback,
    editFeedback,
  } = props;
  console.log('editFeedback', !hideAddFeedBtn || editFeedback);
  const header1 = [
    `${t('ratingHeader1')}`,
    `${t('ratingHeader2')}`,
    `${t('ratingHeader3')}`,
  ];
  const columnFilter1 = header1.map((res, idx) => ({
    title: res,
    className: `${idx === 0 ? 'w-50 text-left' : 'text-center'} `,
    name: res,
    propName: res,
  }));
  const header2 = [
    `${t('ratingHeader4')}`,
    `${t('ratingHeader2')}`,
    `${t('ratingHeader3')}`,
  ];
  const columnFilter2 = header2.map((res, idx) => ({
    title: res,
    className: `${idx === 0 ? 'w-50 text-left' : 'text-center'} `,
    name: res,
    propName: res,
  }));
  const header3 = [
    `${t('ratingHeader5')}`,
    `${t('ratingHeader2')}`,
    `${t('ratingHeader3')}`,
  ];
  const columnFilter3 = header3.map((res, idx) => ({
    title: res,
    className: `${idx === 0 ? 'w-50 text-left' : 'text-center'} `,
    name: res,
    propName: res,
  }));
  const rowsData1 = [
    {
      groupName: `${t('ratingValue1')}`,
      groupDesc: `${t('ratingDesc1')}`,
      key: 'enterprising',
      textKey: 'enterprisingRemarks',
    },
    {
      groupName: `${t('ratingValue2')}`,
      groupDesc: `${t('ratingDesc2')}`,
      key: 'innovative',
      textKey: 'innovativeRemarks',
    },
    {
      groupName: `${t('ratingValue3')}`,
      groupDesc: `${t('ratingDesc3-1')}`,
      key: 'resilience',
      textKey: 'resilienceRemarks',
      className: 'ml-4',
    },
    {
      groupName: `${t('ratingDesc3')}`,
      groupDesc: `${t('ratingDesc3-2')}`,
      key: 'resilience2',
      hideGroupName:true,
      textKey: 'resilience2Remarks',
      className: 'ml-4',
    },
  ];
  const rowsData2 = [
    {
      groupName: `${t('ratingValue8')}`,
      groupDesc: `${t('ratingDesc8')}`,
      key: 'hardskills',
      textKey: 'hardskillsRemarks',
    },
    {
      groupName: `${t('ratingValue9')}`,
      groupDesc: `${t('ratingDesc9')}`,
      key: 'skillbasedachievements',
      textKey: 'skillbasedachievementsRemarks',
    },
  ];
  const rowsData3 = [
    {
      groupName: `${t('ratingValue4')}`,
      groupDesc: `${t('ratingDesc4')}`,
      key: 'initiativeProactive',
      textKey: 'initiativeProactiveRemarks',
    },
    {
      groupName: `${t('ratingValue5')}`,
      groupDesc: `${t('ratingDesc5')}`,
      key: 'resourcefulness',
      textKey: 'resourcefulnessRemarks',
    },
    {
      groupName: `${t('ratingValue6')}`,
      groupDesc: `${t('ratingDesc6')}`,
      key: 'motivation',
      textKey: 'motivationRemarks',
    },
    {
      groupName: `${t('ratingValue7')}`,
      groupDesc: `${t('ratingDesc7')}`,
      key: 'independence',
      textKey: 'independenceRemarks',
    },
  ];

  let tiers = [
    {
      name: 'Tier 1',
      desc : '	Launched a business which is sustainable and profit-making. Candidate has a vision for the business with a view to scale it in the future. Ideally, the business should have a customer base and should be active for at least 6 months.'
    },
    {
      name: 'Tier 2',
      desc : 'A student who took initiative to start a venture (e.g. blogshop, home bakery, gaming currency business etc). He/She is consistently engaged in business activities. Could have initiated social projects on their own. Took initiative to sign up for hackathons and similar programmes. Students in Tier 2 may also be involved in their family business.'
    },
    {
      name: 'Tier 3',
      desc : 'Took inititaive to learn new skills and put those into practice. No venture launched. Consistently engaged in part-time work.'
    },
    {
      name: 'Tier 4',
      desc : 'Consider for overseas Internship (OI-Domain)'
    },
    {
      name: 'Tier 5',
      desc : 'Fail'
    }
  ]


  let totalScore = 0;
  let average = 0;


  for (let i=0; i < rowsData1.length; i++) {
    // console.log("-- responseData ", responseData, rowsData1[i].key, (responseData && responseData[rowsData1[i].key] && Number(responseData[rowsData1[i].key])));
    if (responseData && responseData[rowsData1[i].key] && Number(responseData[rowsData1[i].key])) {
      totalScore += (responseData && responseData[rowsData1[i].key] && Number(responseData[rowsData1[i].key]));
    }
  }

  for (let i=0; i < rowsData2.length; i++) {
    if (responseData && responseData[rowsData2[i].key] && Number(responseData[rowsData2[i].key])) {
      totalScore += (responseData && responseData[rowsData2[i].key] && Number(responseData[rowsData2[i].key]));
    }
  }

  for (let i=0; i < rowsData3.length; i++) {
    if (responseData && responseData[rowsData3[i].key] && Number(responseData[rowsData3[i].key])) {
      totalScore += (responseData && responseData[rowsData3[i].key] && Number(responseData[rowsData3[i].key]));
    }
  }

  if (totalScore > 0) {
    average = totalScore / 9;
  }

// console.log("=== TOTAL SCORE ", totalScore);

  return (
    <Fragment>
      <div className="text-right mb-2">
        {userFeedbackDone !== true && hideAddFeedBtn === false && (
          <Button kind="primary" onClick={handleShowFeedback}>
            {t('addFeedback')}
          </Button>
        )}
        {propUserId == userId && (
          <Button kind="tertiary" onClick={handleEditFeedback}>
            {t('edit')}
          </Button>
        )}
      </div>
      {showFeedback === true && (
        <Fragment>
          <div className="form-heading-font">{t('rateprofile')}</div>
          <div className="mt-2 bx--type-zeta">{t('rateScale')}</div>
          <div className="mt-4 bx--row justify-content-center">
            <div className="bx--col-lg-2 bx--col-xl-2 text-center bx--col-sm-4 bx--col-md-2 rating-scale-border mt-1">
              <div className="rating-size">1</div>
              <div>{t('starRating1')}</div>
            </div>
            <div className="bx--col-lg-2 bx--col-xl-2 text-center bx--col-sm-4 bx--col-md-2 rating-scale-border mt-1">
              <div className="rating-size">2</div>
              <div className="mt-1">{t('starRating2')}</div>
            </div>
            <div className="bx--col-lg-2 bx--col-xl-2 text-center bx--col-sm-4 bx--col-md-2 rating-scale-border mt-1">
              <div className="rating-size">3</div>
              <div className="mt-1">{t('starRating3')}</div>
            </div>
            <div className="bx--col-lg-2 bx--col-xl-2 text-center bx--col-sm-4 bx--col-md-2 rating-scale-border mt-1">
              <div className="rating-size">4</div>
              <div className="mt-1">{t('starRating4')}</div>
            </div>
            <div className="bx--col-lg-2 bx--col-xl-2 text-center bx--col-sm-4 bx--col-md-2 rating-scale-border mt-1">
              <div className="rating-size">5</div>
              <div className="mt-1">{t('starRating5')}</div>
            </div>
          </div>

          <GenericDataTable
            title=""
            customSorting={() => {}}
            rowClass="p-0"
            headers={[{title: 'Tier', className:'w-25'}, {title: 'Description'}].map((data, index) => ({
              key: `header_${index}`,
              header: data.title,
              className: data.className,
              colpropName: data.propName,
            }))}
            rows={tiers.map((data, idx) => {
              const { name, desc } = data || {};
              return {
                isExpanded: false,
                id: idx,
                header_0: (
                  <div>
                    <div className="bx--type-zeta">{name}</div>
                  </div>
                ),
                header_1: (
                  <div>
                    <div className="bx--type-zeta">{desc}</div>
                  </div>
                )
              };
            })}
            selectable={false}
            searchable={false}
            onSearchInputChange={() => {}}
            expandable={false}
          />

          <hr/>

          <div className="mt-4 bx--type-delta">Total Score: {totalScore}<strong></strong></div>
          <div className="mt-4 bx--type-delta">Average: {average}<strong></strong></div>

          <div style={{backgroundColor: 'cyan'}}>
            <div className="mt-4 bx--type-zeta">FINAL TIER</div>
            <div>{t('recmMsg')}</div>
            <div className="small">({t('recmMsg1')})</div>
            <div className="d-flex mt-2">
              <div className="d-flex">
                {[1, 2, 3, 4, 5].map((res, idx) => {
                  return (
                    <div className="mr-2">
                      <RadioButton
                        key={
                          !hideAddFeedBtn ||
                          (editFeedback && userId == propUserId)
                            ? `${res}_${idx + 1}`
                            : responseData && responseData.profileFeedbackId
                        }
                        className="pr-2"
                        onChange={() => {
                          handleChangeRadioBtn('recommendation', res);
                        }}
                        id={
                          !hideAddFeedBtn ||
                          (editFeedback && userId == propUserId)
                            ? `${res}_${idx + 1}`
                            : responseData && responseData.profileFeedbackId
                        }
                        name={
                          !hideAddFeedBtn ||
                          (editFeedback && userId == propUserId)
                            ? 'recommendation'
                            : responseData && responseData.profileFeedbackId
                        }
                        disabled={hideAddFeedBtn && editFeedback !== true}
                        labelText={`Tier ${res}`}
                        value={(!hideAddFeedBtn || editFeedback)
                          ? state && state.recommendation
                          : responseData && responseData.recommendation}
                        checked={(!hideAddFeedBtn || editFeedback)
                          ? state &&
                          state.recommendation &&
                          state.recommendation == res
                          : responseData && responseData.recommendation == res}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="mt-2">
            <InterviewFeedbackTable
              columnWithFilter={columnFilter1}
              rowsData={rowsData1}
              handleChange={handleChange}
              state={state}
              hideAddFeedBtn={hideAddFeedBtn}
              responseData={responseData}
              userFeedbackDone={userFeedbackDone}
              editFeedback={editFeedback}
            />
          </div>
          <div className="mt-2">
            <InterviewFeedbackTable
              columnWithFilter={columnFilter2}
              rowsData={rowsData2}
              handleChange={handleChange}
              state={state}
              hideAddFeedBtn={hideAddFeedBtn}
              responseData={responseData}
              userFeedbackDone={userFeedbackDone}
              editFeedback={editFeedback}
            />
          </div>
          <div className="mt-2">
            <InterviewFeedbackTable
              columnWithFilter={columnFilter3}
              rowsData={rowsData3}
              handleChange={handleChange}
              state={state}
              hideAddFeedBtn={hideAddFeedBtn}
              responseData={responseData}
              userFeedbackDone={userFeedbackDone}
              editFeedback={editFeedback}
            />
          </div>
          {state.recommendationInvalid === true && (
            <InlineNotification
              lowContrast
              kind="error"
              title={t('offerMsg4')}
              subtitle=""
              hideCloseButton
              onCloseButtonClick={() => {}}
            />
          )}
          <hr />
          <div className="bx--type-zeta mt-3 mb-2">{t('feedbackComments')}</div>
          <div>
            <TextArea
              name="backgroundnotes"
              value={
                !hideAddFeedBtn || editFeedback
                  ? state.backgroundnotes
                  : responseData.backgroundnotes
              }
              onChange={handleChange}
              rows={12}
              hideLabel
              disabled={hideAddFeedBtn && editFeedback !== true}
            />
          </div>
          <div className="d-flex align-items-center justify-content-between mt-3">
            <div className="d-flex align-items-center">
              <div>{t('nameOfInterviewer')}:</div>
              <div className="ml-2 font-weight-bold text-capitalize">
                {firstname} {lastname}
              </div>
            </div>
            {!hideAddFeedBtn && (
              <div className="text-capitalize">
                {t('date')}: <b>{moment().format('MM/DD/YYYY')}</b>
              </div>
            )}
          </div>
          {(userFeedbackDone !== true || editFeedback) && (
            <div className="d-flex justify-content-center mt-3">
              <div>
                <Button kind="tertiary" onClick={handleHideForm}>
                  {t('cancel')}
                </Button>
              </div>
              <div className="ml-2">
                <Button
                  kind={editFeedback ? 'tertiary' : 'primary'}
                  onClick={() => {
                    handleSubmitFeedbackForm(
                      responseData ? responseData.profileFeedbackId : null
                    );
                  }}>
                  {editFeedback ? t('edit') : t('save')}
                </Button>
              </div>
            </div>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default withTranslation()(InterviewFeedbackForm);
